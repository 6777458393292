import styled from 'styled-components';

const barWidthMobile = '12px';
const barHeightMobile = '2px';
const barSpacingMobile = '4px';

const barWidthTablet = '18px';
const barHeightTablet = '2px';
const barSpacingTablet = '5px';

const barWidthLaptop = '18px';
const barHeightLaptop = '2px';
const barSpacingLaptop = '5px';

const barWidthDesktop = '18px';
const barHeightDesktop = '2px';
const barSpacingDesktop = '5px';

const animationTime = '0.5s';

export const StyledHamburgerWrapper = styled.div`
  position: fixed;
  top: 3vh;
  right: 5vw;
  cursor: pointer;
  background-color: ${props =>
    props.animateNav || props.isCulturePage
      ? '#fff'
      : props.theme.primaryCherry};
  background-color: ${props =>
    props.animateNav || (!props.showHamburger && props.isCulturePage)
      ? '#fff'
      : props.theme.primaryCherry};
  transition: background-color ${animationTime};
  justify-content: center;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 9;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    width: 48px;
    height: 48px;
  }

  @media screen and (min-width: 1440px) {
    display: ${props => (props.showHamburger ? 'flex' : 'none')};
    /* background-color: ${props =>
      props.animateNav ? props.theme.primaryCherry : '#fff'}; */
    background-color: ${props => props.theme.primaryCherry};
  }
`;

export const StyledHamburger = styled.div`
  width: ${barWidthMobile};
  height: ${barHeightMobile};
  position: relative;
    /* background-color: ${props =>
      props.isCulturePage && !props.animateNav
        ? props.theme.primaryCherry
        : props.animateNav
        ? 'rgba(255, 255, 255, 0)'
        : '#fff'}; */

  background-color: ${props =>
    !props.animateNav
      ? props.isCulturePage && !props.showHamburger
        ? props.theme.primaryCherry
        : '#ffffff'
      : !props.isMenuOpen && '#ffffff'};
  
  /* background-color: ${props =>
    !props.animateNav
      ? props.theme.primaryCherry
      : !props.isMenuOpen && '#ffffff'}; */

  @media screen and (min-width: 1440px) {
    background-color: ${props =>
      props.animateNav && props.isMenuOpen
        ? props.theme.primaryCherry
        : '#ffffff'};
      
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    width: ${barWidthTablet};
    height: ${barHeightTablet};
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    width: ${barWidthLaptop};
    height: ${barHeightLaptop};
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    width: ${barWidthDesktop};
    height: ${barHeightDesktop};
  }

  &,
  &::before,
  &::after {
    transition: opacity ${animationTime}, transform ${animationTime},
      background-color ${animationTime}, bottom ${animationTime},
      top ${animationTime};
  }

  &:before,
  &:after {
    /* background-color: ${props =>
      props.animateNav || props.isCulturePage
        ? props.theme.primaryCherry
        : '#fff'}; */
    background-color: ${props =>
      !props.animateNav
        ? props.isCulturePage && !props.showHamburger
          ? props.theme.primaryCherry
          : '#ffffff'
        : !props.isMenuOpen
        ? '#ffffff'
        : props.theme.primaryCherry};
    content: '';
    width: ${barWidthMobile};
    height: ${barHeightMobile};
    position: absolute;
    left: 0;
    @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
      width: ${barWidthTablet};
      height: ${barHeightTablet};
    }

    @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
      width: ${barWidthLaptop};
      height: ${barHeightLaptop};
    }

    @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
      width: ${barWidthDesktop};
      height: ${barHeightDesktop};
    }
    @media screen and (min-width: 1440px) {
      /* background-color: ${props =>
        props.isMenuOpen ? '#ffffff' : 'red'}; */
      /* background-color: ${props =>
        !props.animateNav ? 'inherit' : '#ffffff'}; */
      background-color: #ffffff;
    }
  }

  &::before {
    bottom: ${barSpacingMobile};
    transform: ${props => (props.animateNav ? 'rotate(-45deg)' : 'rotate(0)')};
    @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
      bottom: ${barSpacingTablet};
    }

    @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
      bottom: ${barSpacingLaptop};
    }

    @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
      bottom: ${barSpacingDesktop};
    }

    ${props => {
      return props.animateNav ? 'bottom: calc(50% - 1px) !important;' : '';
    }}
  }

  &::after {
    top: ${barSpacingMobile};
    transform: ${props => (props.animateNav ? 'rotate(45deg)' : 'rotate(0)')};
    @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
      top: ${barSpacingTablet};
    }

    @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
      top: ${barSpacingLaptop};
    }

    @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
      top: ${barSpacingDesktop};
    }
    ${props => {
      return props.animateNav ? 'top: calc(50% - 1px) !important;' : '';
    }}
  }
`;
